import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import {
    Add as AddIcon,
    Edit as EditIcon,
    Autorenew as AutorenewIcon
} from "@material-ui/icons";
import { Select, MenuItem } from "@material-ui/core";

import { Gap10, Gap30, Gap50 } from "legacy-js/react/components/Gap";
import { getStaticUrl, serverUrl } from "legacy-js/config";
import { User } from "js/core/models/user";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { BEAUTIFUL_WORKSPACE_ID } from "legacy-common/constants";
import { Divider } from "legacy-js/react/components/UiComponents";
import FetchingClickShield from "legacy-js/react/components/FetchingClickShield";
import getLogger, { LogGroup } from "js/core/logger";

// To avoid circular dependency, we need to import the styled components from the SharedStyles file
import { LinkWrapper, LogoContainer, LogoImage } from "../officeHelpers/SharedStyles";

const logger = getLogger(LogGroup.PPT_ADDIN);

const useStyles = makeStyles({
    noUnderline: {
        "&:before": {
            content: "none",
        },
        "&:after": {
            content: "none",
        },
    },
    MuiSelect: {
        "& .MuiSelect-select": {
            // Add your styles here
            background: "#222222 !important",
            color: "#A9A9A9",
        },
    },
});

const ButtonsContainer = styled.div`
    height: calc(100% - 80px);
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const ActionButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;

const Text = styled.div.attrs(({ style }) => ({ style: style ?? {} }))`
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #A9A9A9;
`;

const ActionButtonIconContainer = styled.div.attrs(({ backgroundColor, disabled }) => ({
    style: {
        backgroundColor,
        pointerEvents: disabled ? "none" : "auto",
        cursor: disabled ? "default" : "pointer"
    }
}))`
    width: 40px;
    height: 40px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
        background-color: rgb(11, 118, 158);
    }
`;

const ActionButtonText = styled.div`
    margin-top: 15px;

    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #A9A9A9;
    cursor: default;
`;

function ActionButton({
    text,
    Icon,
    onClick,
    backgroundColor = "#11A9E2",
    disabled = false
}) {
    return (<ActionButtonContainer>
        <ActionButtonIconContainer
            backgroundColor={backgroundColor}
            onClick={onClick}
            disabled={disabled}
        >
            {Icon}
        </ActionButtonIconContainer>
        <ActionButtonText>
            {text}
        </ActionButtonText>
    </ActionButtonContainer>);
}

const AuthenticatedUserTaskpane = ({ firebaseUser, currentSlideModel, currentTeamSlideHasUpdates, openEditorDialog, orgId, onError }) => {
    const [workspaces, setWorkspaces] = useState([]);
    const [workspaceId, setWorkspaceId] = React.useState("personal");
    const [open, setOpen] = React.useState(false);
    const [ready, setReady] = React.useState(false);
    const classes = useStyles();

    const isTeamSlide = !!currentSlideModel?.libraryItemId;

    useEffect(() => {
        const load = async () => {
            // create the user
            app.user = new User({ id: firebaseUser.uid }, { autoLoad: false });
            // load the User model
            await ds.setup();
            await app.user.load();

            const workspaces = Object.keys(app.user.workspaces).map(workspaceId => {
                if (workspaceId === "personal") {
                    return ({
                        value: "personal",
                        label: "Personal workspace"
                    });
                }

                return ({
                    value: workspaceId,
                    label: app.user.getWorkspaceName(workspaceId)
                });
            });

            // If the user only has the personal workspace, don't show the workspace dropdown
            if (workspaces.length === 1 && workspaces[0].value === "personal") {
                setReady(true);
                return;
            }

            setWorkspaces(workspaces);
            setWorkspaceId(workspaces.find(workspace => workspace.value === localStorage.getItem(BEAUTIFUL_WORKSPACE_ID))
                ? localStorage.getItem(BEAUTIFUL_WORKSPACE_ID)
                : "personal");

            setReady(true);
        };

        load()
            .catch(err => {
                logger.error(err, "[AuthenticatedUserTaskpane] error loading, will retry..");
                return load()
                    .catch(err => {
                        logger.error(err, "[AuthenticatedUserTaskpane] failed to load on retry");
                        onError(err);
                    });
            });
    }, []);

    const handleChange = event => {
        setWorkspaceId(event.target.value);
        localStorage.setItem(BEAUTIFUL_WORKSPACE_ID, event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <FetchingClickShield visible={!ready} backgroundColor="black" />
            <ButtonsContainer>
                <ActionButton
                    onClick={() => openEditorDialog(true, workspaceId)}
                    text="add slide"
                    Icon={<AddIcon style={{ width: 32, height: 32 }} />}
                />
                {currentSlideModel && <>
                    <Gap50 />
                    {isTeamSlide &&
                        <ActionButton
                            backgroundColor={currentTeamSlideHasUpdates ? "#FFBB43" : "#222222"}
                            onClick={() => openEditorDialog(false, workspaceId)}
                            disabled={!currentTeamSlideHasUpdates}
                            text={currentTeamSlideHasUpdates ? "update team slide" : "no updates available"}
                            Icon={<AutorenewIcon style={{ width: 22, height: 22, color: currentTeamSlideHasUpdates ? "#FFFFFF" : "#666666" }} />}
                        />
                    }
                    {!isTeamSlide &&
                        <ActionButton
                            onClick={() => openEditorDialog(false, workspaceId)}
                            text="edit slide"
                            Icon={<EditIcon style={{ width: 22, height: 22 }} />}
                        />
                    }
                </>}
            </ButtonsContainer>

            <LogoContainer>
                <LogoImage src={getStaticUrl("/images/ppt-addin/logo.png")} />

                <Gap30 />
                {workspaces.length > 0 && <Select
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={orgId ?? workspaceId}
                    label="workspace"
                    onChange={handleChange}
                    className={`${classes.noUnderline} ${classes.MuiSelect}`}
                >
                    {
                        workspaces.map((workspace, index) => (
                            <MenuItem disabled={!!orgId} key={index} value={workspace.value}>
                                {workspace.label}
                            </MenuItem>
                        ))
                    }
                    <Divider margin={10} />
                    <MenuItem
                        onClick={() => {
                            window.location = `/ppt-addin/logout?continue=${encodeURIComponent("/ppt-addin/taskpane")}`;
                        }}
                    >
                        Sign Out
                    </MenuItem>
                </Select>}
                <Gap10 />
                <LinkWrapper href={serverUrl} target="_blank">
                    <Text>
                        go to library
                    </Text>
                </LinkWrapper>
                {
                    // if we have only a personal workspace, we do not show the dropdown, adding this option to allow users to sign out
                    workspaces.length === 0 && <>
                        <Gap10 />
                        <Text
                            style={{ cursor: "pointer", marginTop: "8px" }}
                            onClick={() => window.location = `/ppt-addin/logout?continue=${encodeURIComponent("/ppt-addin/taskpane")}`}
                        >
                            sign out
                        </Text>
                    </>
                }

            </LogoContainer>
        </>
    );
};

export default AuthenticatedUserTaskpane;
